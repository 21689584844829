import React from "react";

const Models = React.lazy(() => import("./views/pages/Models/Models"));
const Single = React.lazy(() => import("./views/pages/Single/Single"));
// const Home = React.lazy(() => import('./views/pages/Home/Home'));
const Profile = React.lazy(() => import("./views/pages/profile/Profile"));
const Download = React.lazy(() => import("./views/pages/download/Download"));
// const Downloads = React.lazy(() => import("./views/pages/downloads/Downloads"));
const Bills = React.lazy(() => import("./views/pages/bills/Bills"));
const Tickets = React.lazy(() => import("./views/pages/tickets/Tickets.js"));
const Subscriptions = React.lazy(() => import("./views/pages/subscriptions/Subscriptions"));
const PaymentSuccess = React.lazy(() => import("./views/pages/payment/Success"));
const PaymentFail = React.lazy(() => import("./views/pages/payment/Fail"));

const routes = [
    // { path: '/home', name: 'Home', element: Home },//removed by owner request
    { path: '/models/show/:slug', name: 'Single', element: Single },
    { path: '/models', name: 'Models', element: Models },
    { path: '/model/download/:slug', name: 'Download', element: Download },
    { path: '/profile', name: 'Profile', element: Profile },
    // { path: '/downloads', name: 'My Downloads', element: Downloads },//removed by owner request
    { path: '/bills', name: 'My Bills', element: Bills },
    { path: '/tickets', name: 'My Tickets', element: Tickets },
    { path: '/subscriptions', name: 'My Subscriptions', element: Subscriptions },
    { path: '/payment-success', name: 'Successfull payment', element: PaymentSuccess },
    { path: '/payment-fail', name: 'Failed payment', element: PaymentFail },

]

export default routes;
