import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: process.env.NODE_ENV == 'development',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      orginal: {
        translation: {
          en: 'English',
          az: 'Azerbaijani',
          ru: 'Russian',
          nextPage: 'Next Page',
          first: 'First',
          last: 'Last',
          previous: 'Prev',
          next: 'Next',
          newmodels: 'New models',
          '3dmodels': '3D Models',
          searchplaceholder: 'Search 3D models, textures, materials...',
          adminpanel: 'Admin Panel',
          dashboard: 'Dashboard',
          bills: 'Payment',
          models: 'Models',
          home: 'Home',
          searchTerm: 'SearchTerm',
          delete: 'Delete',
          saveChanges: 'Save Changes',
          addNew: 'Add new',
          list: 'List',
          actions: 'Actions',
          translate: 'Translate',
          translation: 'Translation',
          selectLanguage: 'Select Language',
          title: 'Title',
          memberships: 'Memberships',
          description: 'Description',
          resetPassword: 'Reset password',
          usernamePlaceholder: 'Username',
          passwordConfirmPlaceholder: 'Password confirm',
          facebookLinkPlaceholder: 'Facebook Link',
          continue: 'Continue',
          back: 'Back',
          checkYourEmail: 'Check your email',
          membershipRemainedMessage: 'Membership remained message',
          unspecified: 'Unspecified',
          cancel: 'Cancel',
          apply: 'Apply',
          create: 'Create',
          passwordNotMatch: 'Password does not match',
          deleteConfirm: 'Are you sure?',
          emptyTable: 'There is no data available to show',
          signin: 'Sign in',
          emailPlaceholder: 'Enter email',
          passwordPlaceholder: 'Enter password',
          forgotPassword: 'Forgot password',
          signup: 'Sign up',
          profile: 'Profile',
          downloads: 'Downloads',
          download: 'Download',
          logout: 'Logout',
          Home: 'Home',
          clearFilters: 'Clear Filters',
          style: 'Style',
          render: 'Render',
          format: 'Format',
          form: 'Form',
          color: 'Color',
          material: 'Material',
          close: 'Close',
          modelsFound: 'models found',
          downloadNow: 'Download now',
          dailyLimitReached: 'Daily download limit reached',
          generateDownloadLink: 'Generate download link',
          showMore: 'Show more',
          users: 'Users',
          memebership: 'Memebership',
          unreadBills: 'Unread Payment',
          allModels: 'All Models',
          collectedModels: 'Collected Models',
          new: 'New',
          edit: 'Edit',
          save: 'Save',
          ID: 'ID',
          userID: 'User ID',
          startDate: 'Start Date',
          endDate: 'End Date',
          status: 'Status',
          day: 'Day(s)',
          membership_remained_message: 'This shows remained days of your membership',
          first_name: 'First Name',
          last_name: 'Last Name',
          register_date: 'Registration Date',
          email: 'Email',
          gender: 'Gender',
          birth_date: 'Date of Birth',
          resetPasswordMessage:
            'A link to reset your password has been sent to your email {{email}}. If you can not find the letter in a mailbox, check Spam folder.',
          remainedDownloads: 'You can do {{remained}} more downloads',
          remainedDays: '{{diff}} days of your membership remains',
          pendingUsers: 'Pending users',
          membership: 'Membership',
          id: 'ID',
          start_date: 'Start date',
          end_date: 'End date',
          user: 'User',
          username: 'Username',
          facebook_id: 'Facebook id',
          bill: 'Payment',
          user_id: 'User id',
          modelName: 'Model name',
          name: 'Name',
          model: 'Model',
          size: 'Size',
          category: 'Category',
          slug: 'Slug',
          parent_id: 'Parent id',
          image: 'Image',
          path: 'Path',
          platform: 'Platform',
          tag: 'Tag',
          type: 'Type',
          model_id: 'Model id',
          category_id: 'Category id',
          form_id: 'Form id',
          platform_id: 'Platform id',
          render_id: 'Render id',
          style_id: 'Style id',
          type_id: 'Type id',
          materials: 'Materials',
          colors: 'Colors',
          formats: 'Formats',
          tags: 'Tags',
          polygons: 'Polygons',
          width: 'Width',
          height: 'Height',
          length: 'Length',
          financial: 'Financial',
          management: 'Management',
          files: 'Files',
          basic: 'Basic',
          account: 'Account',
          subscriptions: 'Subscriptions',
          package_selector_label: 'Select one of the subscription packages',
          success_payment: 'Your payment is successfully done.',
          tickets: 'Tickets',
          notification: 'Notification',
          pageNumber: 'Page number',
          file_number: 'File number',
          notification_id: 'Notification id',
          auto_hide: 'Auto hide',
          optimized: 'Optimized',
          pending: 'Waiting',
          closed: 'Closed',
          answered: 'Answered',
          contact_admin: 'Contact admin',
          create_ticket: 'Create ticket',
          send: 'Send',
          create_new_ticket: 'Create new ticket',
          subject: 'Subject',
          message: 'Message',
          payment_save_error:
            'Your payment successfully done but something went wrong. your subscription will automatically generated. if there is a problem contact us to solve the problem.',
          change_password: 'Change Password',
          previous_password: 'Previous Password',
          new_password: 'New Password',
          password_confirm: 'Password Confirm',
          show_passwords: 'Show Passwords',
          update_password: 'Update Password',
          male: 'Male',
          female: 'Female',
          newest: 'Newest',
          oldest: 'Oldest',
          'Favorite models': 'Favorite models',
          INSERT_DOWNLOAD_LINK: 'Please insert download link here.',
          BOOKMARK_ADDED: 'The model added to your bookmark list.',
          BOOKMARK_REMOVED: 'The model removed from your bookmark list.',
          dailyLimit: 'Daily limit',
          price: 'Price',
          limit: 'Limit',
          days: 'Days',
          active: 'Active',
          package: 'Package',
        },
      },
      en: {
        translation: {
          en: 'English',
          az: 'Azerbaijani',
          ru: 'Russian',
          nextPage: 'Next Page',
          first: 'First',
          last: 'Last',
          previous: 'Prev',
          next: 'Next',
          newmodels: 'New models',
          '3dmodels': '3D Models',
          searchplaceholder: 'Search 3D models, textures, materials...',
          adminpanel: 'Admin Panel',
          dashboard: 'Dashboard',
          bills: 'Payment',
          models: 'Models',
          home: 'Home',
          searchTerm: 'SearchTerm',
          delete: 'Delete',
          saveChanges: 'Save Changes',
          addNew: 'Add new',
          list: 'List',
          actions: 'Actions',
          translate: 'Translate',
          translation: 'Translation',
          selectLanguage: 'Select Language',
          title: 'Title',
          memberships: 'Memberships',
          description: 'Description',
          resetPassword: 'Reset password',
          usernamePlaceholder: 'Username',
          passwordConfirmPlaceholder: 'Password confirm',
          facebookLinkPlaceholder: 'Facebook Link',
          continue: 'Continue',
          back: 'Back',
          checkYourEmail: 'Check your email',
          membershipRemainedMessage: 'Membership remained message',
          unspecified: 'Unspecified',
          cancel: 'Cancel',
          apply: 'Apply',
          create: 'Create',
          passwordNotMatch: 'Password does not match',
          deleteConfirm: 'Are you sure?',
          emptyTable: 'There is no available data to display.',
          signin: 'Sign in',
          emailPlaceholder: 'Enter email',
          passwordPlaceholder: 'Enter password',
          forgotPassword: 'Forgot password',
          signup: 'Sign up',
          profile: 'Profile',
          downloads: 'Downloads',
          download: 'Download',
          logout: 'Logout',
          Home: 'Home',
          clearFilters: 'Clear Filters',
          style: 'Style',
          render: 'Render',
          format: 'Format',
          form: 'Form',
          color: 'Color',
          material: 'Material',
          close: 'Close',
          modelsFound: 'models found',
          downloadNow: 'Download now',
          dailyLimitReached: 'Daily download limit reached',
          generateDownloadLink: 'Download',
          showMore: 'Show more',
          users: 'Users',
          memebership: 'Memebership',
          unreadBills: 'Unread Payment',
          allModels: 'All Models',
          collectedModels: 'Collected Models',
          new: 'New',
          edit: 'Edit',
          save: 'Save',
          ID: 'ID',
          userID: 'User ID',
          startDate: 'Start Date',
          endDate: 'End Date',
          status: 'Status',
          day: 'Day(s)',
          membership_remained_message: 'This shows remained days of your membership',
          first_name: 'First Name',
          last_name: 'Last Name',
          register_date: 'Registration Date',
          email: 'Email',
          gender: 'Gender',
          birth_date: 'Date of Birth',
          resetPasswordMessage:
            'A link to reset your password has been sent to your email {{email}}. If you can not find the letter in a mailbox, check Spam folder',
          remainedDownloads: 'You can do {{remained}} more downloads',
          remainedDays: '{{diff}} days of your membership remains',
          pendingUsers: 'Pending users',
          membership: 'Membership',
          id: 'ID',
          start_date: 'Start date',
          end_date: 'End date',
          user: 'User',
          username: 'Username',
          facebook_id: 'Facebook id',
          bill: 'Payment',
          user_id: 'User id',
          modelName: 'Model name',
          name: 'Name',
          model: 'Model',
          size: 'Size',
          category: 'Category',
          slug: 'Slug',
          parent_id: 'Parent id',
          image: 'Image',
          path: 'Path',
          platform: 'Platform',
          tag: 'Tag',
          type: 'Type',
          model_id: 'Model id',
          category_id: 'Category id',
          form_id: 'Form id',
          platform_id: 'Platform id',
          render_id: 'Render id',
          style_id: 'Style id',
          type_id: 'Type id',
          materials: 'Materials',
          colors: 'Colors',
          formats: 'Formats',
          tags: 'Tags',
          polygons: 'Polygons',
          width: 'Width',
          height: 'Height',
          length: 'Length',
          financial: 'Financial',
          management: 'Management',
          files: 'Files',
          basic: 'Basic',
          account: 'Account',
          subscriptions: 'Subscriptions',
          package_selector_label: 'Select one of the subscription packages',
          success_payment: 'Your payment is successfully done.',
          tickets: 'Tickets',
          notification: 'Notification',
          pageNumber: 'Page number',
          file_number: 'File number',
          notification_id: 'Notification id',
          auto_hide: 'Auto hide',
          optimized: 'Optimized',
          pending: 'Waiting',
          closed: 'Closed',
          answered: 'Answered',
          contact_admin: 'Contact admin',
          create_ticket: 'Create ticket',
          send: 'Send',
          create_new_ticket: 'Create new ticket',
          subject: 'Subject',
          message: 'Message',
          payment_save_error:
            'Your payment successfully done but something went wrong. your subscription will automatically generated. if there is a problem contact us to solve the problem.',
          change_password: 'Change Password',
          previous_password: 'Previous Password',
          new_password: 'New Password',
          password_confirm: 'Password Confirm',
          show_passwords: 'Show Passwords',
          update_password: 'Update Password',
          male: 'Male',
          female: 'Female',
          newest: 'Newest',
          oldest: 'Oldest',
          'Favorite models': 'Favorite models',
          INSERT_DOWNLOAD_LINK: 'Please insert download link here.',
          BOOKMARK_ADDED: 'The model added to your bookmark list.',
          BOOKMARK_REMOVED: 'The model removed from your bookmark list.',
          dailyLimit: 'Daily limit',
          price: 'Price',
          limit: 'Limit',
          days: 'Days',
          active: 'Active',
          package: 'Package',
        },
      },
      az: {
        translation: {
          en: 'İngilis dili',
          az: 'Azərbaycan',
          ru: 'rus',
          nextPage: 'növbəti səhifə',
          first: 'Birinci',
          last: 'Son',
          previous: 'Əvvəlki',
          next: 'Sonrakı',
          newmodels: 'Yeni modellər',
          '3dmodels': '3D Modellər',
          searchplaceholder: '3D modelləri, texturaları, materialları axtarın...',
          adminpanel: 'İdarəetmə Paneli',
          dashboard: 'İdarə paneli',
          bills: 'Ödəniş',
          models: 'Modellər',
          home: 'Ev',
          searchTerm: 'Axtarış Termi',
          delete: 'Sil',
          saveChanges: 'Dəyişiklikləri yadda saxla',
          addNew: 'Yeni əlavə et',
          list: 'Siyahı',
          actions: 'Tədbirlər',
          translate: 'Tərcümə etmək',
          translation: 'Tərcümə',
          selectLanguage: 'Dil seçin',
          title: 'Başlıq',
          memberships: 'Üzvlüklər',
          description: 'Təsvir',
          resetPassword: 'Parolu sıfırlamak',
          usernamePlaceholder: 'İstifadəçi adı',
          passwordConfirmPlaceholder: 'Şifrəni təsdiqləyin',
          facebookLinkPlaceholder: 'Facebook Linki',
          continue: 'Davam et',
          back: 'Geri',
          checkYourEmail: 'E-poçtunuzu yoxlayın',
          membershipRemainedMessage: 'Üzvlük mesaj olaraq qaldı',
          unspecified: 'Dəqiqləşdirilməmiş, naməlum',
          cancel: 'Ləğv et',
          apply: 'Müraciət edin',
          create: 'Yarat',
          passwordNotMatch: 'Şifrə uyğun gəlmir',
          deleteConfirm: 'Sən əminsən?',
          emptyTable: 'Göstərmək üçün heç bir məlumat yoxdur',
          signin: 'Daxil olun',
          emailPlaceholder: 'E-poçtu daxil edin',
          passwordPlaceholder: 'parol daxil edin',
          forgotPassword: 'Şifrəni unutmusunuz',
          signup: 'Abunə olun',
          profile: 'Profil',
          downloads: 'Yükləmələr',
          download: 'Yüklə',
          logout: 'Çıxış',
          Home: 'Ev',
          clearFilters: 'Filtrləri təmizləyin',
          style: 'Stil',
          render: 'Render',
          format: 'Format',
          form: 'forma',
          color: 'Rəng',
          material: 'Material',
          close: 'Yaxın',
          modelsFound: 'modellər tapıldı',
          downloadNow: 'İndi yüklə',
          dailyLimitReached: 'Gündəlik endirmə limitinə çatdı',
          generateDownloadLink: 'Yükləmə linki yaradın',
          showMore: 'Daha çox göstər',
          users: 'İstifadəçilər',
          memebership: 'Üzvlük',
          unreadBills: 'Oxunmamış Ödəniş',
          allModels: 'Bütün Modellər',
          collectedModels: 'Toplanmış Modellər',
          new: 'Yeni',
          edit: 'Redaktə et',
          save: 'Yadda saxla',
          ID: 'ID',
          userID: 'İstifadəçi adı',
          startDate: 'Başlama tarixi',
          endDate: 'Bitmə vaxtı',
          status: 'Vəziyyət',
          day: 'gün(lər)',
          membership_remained_message: 'Bu, üzvlüyünüzdən qalan günləri göstərir',
          first_name: 'Ad',
          last_name: 'Soyad',
          register_date: 'Qeydiyyat tarixi',
          email: 'E-poçt',
          gender: 'Cins',
          birth_date: 'Doğum tarixi',
          resetPasswordMessage:
            'Parolunuzu sıfırlamaq üçün link {{email}} e-poçtunuza göndərildi. Əgər məktubu poçt qutusunda tapa bilmirsinizsə, Spam qovluğunu yoxlayın.',
          remainedDownloads: 'Siz {{remained}} daha çox endirmə edə bilərsiniz',
          remainedDays: 'Üzvlüyünüzün {{diff}} günü qalır',
          pendingUsers: 'Gözləyən istifadəçilər',
          membership: 'Üzvlük',
          id: 'ID',
          start_date: 'Başlama tarixi',
          end_date: 'Bitmə vaxtı',
          user: 'İstifadəçi',
          username: 'İstifadəçi adı',
          facebook_id: 'Facebook id',
          bill: 'Ödəniş',
          user_id: 'İstifadəçi adı',
          modelName: 'Model adı',
          name: 'ad',
          model: 'Model',
          size: 'Ölçü',
          category: 'Kateqoriya',
          slug: 'Slug',
          parent_id: 'Valideyn id',
          image: 'Şəkil',
          path: 'Yol',
          platform: 'Platforma',
          tag: 'Tag',
          type: 'Növ',
          model_id: 'Model id',
          category_id: 'Kateqoriya id',
          form_id: 'Forma id',
          platform_id: 'Platformanın id',
          render_id: 'Render id',
          style_id: 'Stil id',
          type_id: 'İD yazın',
          materials: 'Materiallar',
          colors: 'Rənglər',
          formats: 'Formatlar',
          tags: 'Teqlər',
          polygons: 'Çoxbucaqlılar',
          width: 'Genişlik',
          height: 'Hündürlük',
          length: 'Uzunluq',
          financial: 'Maliyyə',
          management: 'İdarəetmə',
          files: 'Fayllar',
          basic: 'Əsas',
          account: 'Hesab',
          subscriptions: 'abunələr',
          package_selector_label: 'Abunə paketlərindən birini seçin',
          success_payment: 'Your payment is successfully done.',
          tickets: 'biletlər',
          notification: 'bildiriş',
          pageNumber: 'səhifə nömrəsi',
          file_number: 'fayl_nömrəsi',
          notification_id: 'bildiriş id',
          auto_hide: 'avtomatik gizlət',
          optimized: 'optimallaşdırılmışdır',
          pending: 'gözləyir',
          closed: 'bağlanıb',
          answered: 'cavab verdi',
          contact_admin: 'administratorla əlaqə saxlayın',
          create_ticket: 'bilet yaratmaq',
          send: 'göndər',
          create_new_ticket: 'yeni bilet yaradın',
          subject: 'mövzu',
          message: 'mesaj',
          payment_save_error:
            'Your payment successfully done but something went wrong. your subscription will automatically generated. if there is a problem contact us to solve the problem.',
          change_password: 'Parolun dəyişdirilməsi',
          previous_password: 'Əvvəlki parol',
          new_password: 'Yeni Parol',
          password_confirm: 'Parol Təsdiq',
          show_passwords: 'Parolları göstərin',
          update_password: 'Parolu yeniləyin',
          male: 'Kişi',
          female: 'Qadın',
          newest: 'Newest',
          oldest: 'Oldest',
          'Favorite models': 'Favorite models',
          INSERT_DOWNLOAD_LINK: 'Please insert download link here.',
          BOOKMARK_ADDED: 'The model added to your bookmark list.',
          BOOKMARK_REMOVED: 'The model removed from your bookmark list.',
          dailyLimit: 'Daily limit',
          price: 'Price',
          limit: 'Limit',
          days: 'Days',
          active: 'Active',
          package: 'Package',
        },
      },
      ru: {
        translation: {
          en: 'Английский',
          az: 'Азербайджанский',
          ru: 'Русский',
          nextPage: 'Следующая Страница',
          first: 'Первый',
          last: 'Последний',
          previous: 'Предыдущий',
          next: 'Следующий',
          newmodels: 'Новые модели',
          '3dmodels': '3D модели',
          searchplaceholder: 'Поиск 3D моделей, текстур, материалов...',
          adminpanel: 'Панель администратора',
          dashboard: 'Панель приборов',
          bills: 'Оплата',
          models: 'Модели',
          home: 'Дом',
          searchTerm: 'Искать термин',
          delete: 'Удалить',
          saveChanges: 'Сохранить изменения',
          addNew: 'Добавить новое',
          list: 'Список',
          actions: 'Действия',
          translate: 'Переводить',
          translation: 'Перевод',
          selectLanguage: 'Выберите язык',
          title: 'Заголовок',
          memberships: 'Членство',
          description: 'Описание',
          resetPassword: 'Сброс пароля',
          usernamePlaceholder: 'Имя пользователя',
          passwordConfirmPlaceholder: 'Подтвердить пароль',
          facebookLinkPlaceholder: 'Ссылка на Facebook',
          continue: 'Продолжать',
          back: 'Назад',
          checkYourEmail: 'Проверьте свою электронную почту',
          membershipRemainedMessage: 'Членство осталось сообщение',
          unspecified: 'Неопределенные',
          cancel: 'Отмена',
          apply: 'применять',
          create: 'создавать',
          passwordNotMatch: 'Пароль не подходит',
          deleteConfirm: 'Вы уверены?',
          emptyTable: 'Нет данных для показа',
          signin: 'Войти',
          emailPlaceholder: 'Введите адрес электронной почты',
          passwordPlaceholder: 'Введите пароль',
          forgotPassword: 'Забыли пароль',
          signup: 'Зарегистрироваться',
          profile: 'Профиль',
          downloads: 'Загрузки',
          download: 'Скачать',
          logout: 'Выйти',
          Home: 'Дом',
          clearFilters: 'Очистить фильтры',
          style: 'Стиль',
          render: 'Оказывать',
          format: 'Формат',
          form: 'Форма',
          color: 'Цвет',
          material: 'Материал',
          close: 'Закрывать',
          modelsFound: 'модели найдены',
          downloadNow: 'Скачать сейчас',
          dailyLimitReached: 'Достигнут дневной лимит загрузки',
          generateDownloadLink: 'Создать ссылку для скачивания',
          showMore: 'Показать больше',
          users: 'Пользователи',
          memebership: 'членство',
          unreadBills: 'Непрочитанные Оплата',
          allModels: 'Все модели',
          collectedModels: 'Собранные модели',
          new: 'Новый',
          edit: 'Редактировать',
          save: 'Сохранять',
          ID: 'ИДЕНТИФИКАТОР',
          userID: 'ID пользователя',
          startDate: 'Дата начала',
          endDate: 'Дата окончания',
          status: 'Положение дел',
          day: 'День (дни)',
          membership_remained_message: 'Это показывает оставшиеся дни вашего членства',
          first_name: 'Имя',
          last_name: 'Фамилия',
          register_date: 'Дата регистрации',
          email: 'Электронная почта',
          gender: 'Пол',
          birth_date: 'Дата рождения',
          resetPasswordMessage:
            'Ссылка для сброса пароля отправлена на ваш адрес электронной почты {{email}}. Если вы не можете найти письмо в почтовом ящике, проверьте папку «Спам».',
          remainedDownloads: 'Вы можете загрузить еще {{remained}}',
          remainedDays: 'Осталось {{diff}} дней вашего членства',
          pendingUsers: 'Ожидающие пользователи',
          membership: 'Членство',
          id: 'ИДЕНТИФИКАТОР',
          start_date: 'Дата начала',
          end_date: 'Дата окончания',
          user: 'Пользователь',
          username: 'Имя пользователя',
          facebook_id: 'Facebook ID',
          bill: 'Оплата',
          user_id: 'ID пользователя',
          modelName: 'Название модели',
          name: 'Имя',
          model: 'Модель',
          size: 'Размер',
          category: 'Категория',
          slug: 'Слизняк',
          parent_id: 'Родительский идентификатор',
          image: 'Изображение',
          path: 'Путь',
          platform: 'Платформа',
          tag: 'Ярлык',
          type: 'Тип',
          model_id: 'Идентификатор модели',
          category_id: 'Идентификатор категории',
          form_id: 'Идентификатор формы',
          platform_id: 'Идентификатор платформы',
          render_id: 'Идентификатор рендеринга',
          style_id: 'Идентификатор стиля',
          type_id: 'Идентификатор типа',
          materials: 'Материалы',
          colors: 'Цвета',
          formats: 'Форматы',
          tags: 'Теги',
          polygons: 'Полигоны',
          width: 'Ширина',
          height: 'Высота',
          length: 'Длина',
          financial: 'финансовый',
          management: 'Управление',
          files: 'Файлы',
          basic: 'Базовый',
          account: 'Счет',
          subscriptions: 'Подписки',
          package_selector_label: 'Выберите один из пакетов подписки',
          success_payment: 'Your payment is successfully done.',
          tickets: 'тикеты',
          notification: 'уведомление',
          pageNumber: 'номер страницы',
          file_number: 'номер файла',
          notification_id: 'идентификатор уведомления',
          auto_hide: 'автоматически скрыть',
          optimized: 'оптимизировано',
          pending: 'ожидание',
          closed: 'закрыто',
          answered: 'отвечено',
          contact_admin: 'связаться с администратором',
          create_ticket: 'создать тикет',
          send: 'отправить',
          create_new_ticket: 'создать новый тикет',
          subject: 'тема',
          message: 'сообщение',
          payment_save_error:
            'Your payment successfully done but something went wrong. your subscription will automatically generated. if there is a problem contact us to solve the problem.',
          change_password: 'Изменить пароль',
          previous_password: 'Предыдущий пароль',
          new_password: 'Новый пароль',
          password_confirm: 'Подтверждение пароля',
          show_passwords: 'Показать пароли',
          update_password: 'Обновить пароль',
          male: 'Мужской',
          female: 'Женский',
          newest: 'Newest',
          oldest: 'Oldest',
          'Favorite models': 'Favorite models',
          INSERT_DOWNLOAD_LINK: 'Please insert download link here.',
          BOOKMARK_ADDED: 'The model added to your bookmark list.',
          BOOKMARK_REMOVED: 'The model removed from your bookmark list.',
          dailyLimit: 'Daily limit',
          price: 'Price',
          limit: 'Limit',
          days: 'Days',
          active: 'Active',
          package: 'Package',
        },
      },
    },
  })

export default i18n
