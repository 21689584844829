import { cifAz, cifRu, cifUs, cilBell, cilEnvelopeClosed, cilMoon, cilSend, cilSun } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CAlert,
  CBadge,
  CContainer,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFormInput,
  CImage,
} from '@coreui/react'
import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router-dom'
import logo from 'src/assets/brand/logo.png'
import logo_light from 'src/assets/brand/logo_light.png'
import { getHeaders, logout, styler } from 'src/common'
import { env, REACT_APP_SERVER_API } from 'src/config'
import { useTheme } from 'src/providers/Theme'
import { useUser } from 'src/providers/User'
import s from './header.module.css'
import LanguageChanger from './LanguageChanger'
import blankAvatar from 'src/assets/images/blank.svg'
import { errorHandler } from 'src/handlers/ErrorHandler'
import { simpleToast, useToast } from 'src/providers/Toaster'

function Header() {
  const [searchTerm, setSearchTerm] = useState('')
  const [userDrowpdown, setUserDrowpdown] = useState(false)
  const [loading, setLoading] = useState(false)
  const [waiting, setWaiting] = useState(false)
  const [notifications, setNotifications] = useState([])
  const { user } = useUser()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const { theme, setTheme } = useTheme()
  const [dark, setDark] = useState(theme == 'theme-dark')
  const { setToast } = useToast()
  const [messageCount, setMessageCount] = useState(0)
  useEffect(() => {
    let notification_id = localStorage.getItem('notification_id') ?? 0
    axios
      .post(
        env(REACT_APP_SERVER_API) + 'notification/active_notification',
        { notification_id, lang: i18n.resolvedLanguage },
        { headers: getHeaders() },
      )
      .then((response) => {
        setNotifications(response.data.result)
        setNotifications(
          i18n.resolvedLanguage == 'en'
            ? response.data.result
            : response.data.result.map((i) => ({
                ...i,
                title: i?.translate?.[0]?.title ?? i.title,
                description: i?.translate?.[0]?.description ?? i.description,
              })),
        )
        for (const notification of response.data.result) {
          console.log(notification)
          if (notification.auto_hide > 0)
            setTimeout(() => {
              setNotifications((n) => {
                let deleted = n.splice(
                  n.findIndex((i) => i.id == notification.id),
                  1,
                )
                console.log({ deleted })
                return [...n]
              })
            }, notification.auto_hide * 1000)
        }
      })
  }, [i18n.resolvedLanguage])

  const checkTickets = useCallback(() => {
    axios
      .get(env(REACT_APP_SERVER_API) + 'ticket/', { headers: getHeaders() })
      .then((response) => {
        // setTickets(response.data)
        setMessageCount(response.data.filter((i) => i.status == 'answered').length)
      })
      .catch((error) =>
        errorHandler(error, (code, message) => {
          setToast(simpleToast(message, 'danger'))
        }),
      )
  }, [])

  useEffect(() => {
    checkTickets()
    let i = setInterval(checkTickets, 10 * 60 * 1000)
    return () => clearInterval(i)
  }, [])

  // useEffect(() => {
  //   if (loading)
  //     return;
  //   if (searchTerm.length < 4)
  //     return;
  //   if (waiting)
  //     return;
  //   setWaiting(true)
  //   setTimeout(() => {
  //     setWaiting(false);
  //     setLoading(true);
  //     axios.post(env(REACT_APP_SERVER_API) + 'model/search', { term: searchTerm, page: 1, perPage: 10, lang: i18n.resolvedLanguage }, { headers: getHeaders() })
  //       .then(response => {

  //       }).finally(() => {
  //         setLoading(false);
  //       })

  //   }, 500)
  // }, [searchTerm])

  const handleTheme = (value) => {
    setTheme(value ? 'theme-dark' : 'theme-default')
    setDark(value)
  }
  return (
    // <div>
    <div
      className={styler(s, 'old-container-fluid sky-header-underline ' + theme)}
      data-target="block-main-menu"
    >
      <CContainer>
        <nav className={styler(s, 'header')}>
          <div className={styler(s, 'header-top bottom-panel-authorized')}>
            <ul className={styler(s, 'header-menu')}>
              {/* <li>
                <a href="#/home">{t('home')}</a>
              </li> */}
              <li>
                <a href="#/models">{t('3dmodels')}</a>
              </li>

              {user?.is_admin && (
                <li>
                  <a href="#/admin">{t('adminpanel')}</a>
                </li>
              )}
              <li>
                <LanguageChanger />
                {/* <CButtonGroup>
                  {Object.keys(lngs).map((lng) => (
                    <CButton color='light' key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
                      <CIcon size='xxl'
                        icon={lngs[lng].icon}

                      />
                    </CButton>
                  ))}
                </CButtonGroup> */}
              </li>
            </ul>
            <div
              className={styler(s, 'checkcontainer')}
              htmlFor={'dark-light-toogle'}
              onClick={() => handleTheme(!dark)}
            >
              {/* <input checked={dark} type="checkbox" id={"dark-light-toogle"} /> */}
              {
                <CIcon
                  icon={dark ? cilSun : cilMoon}
                  style={{ color: dark ? '#ffe457' : '#004' }}
                  size="xl"
                />
              }
            </div>
          </div>
          <div className={styler(s, 'header-content')}>
            <div className={styler(s, 'd-flex')}>
              <div className={styler(s, 'align-self-center logo')}>
                <div>
                  <a className={styler(s, 'logo')} href="/" rel="nofollow">
                    <CImage src={dark ? logo_light : logo} alt="" height={40} />
                  </a>
                </div>
              </div>
              <div className={styler(s, 'd-flex align-items-center search ')}>
                <div
                  className={styler(s, 'input-elements cleaner-ico')}
                  id="cleaner"
                  onClick={() => {}}
                  style={{ display: 'none' }}
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893C12.0976 0.683417 12.0976 1.31658 11.7071 1.70711L7.41421 6L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071C11.3166 12.0976 10.6834 12.0976 10.2929 11.7071L6 7.41421L1.70711 11.7071C1.31658 12.0976 0.683417 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div
                  className={styler(s, 'input-elements vertical-line')}
                  id="cleaner_vertical_line"
                  style={{ display: 'none' }}
                ></div>
                <div
                  className={styler(s, 'input-elements ng-search-ico')}
                  id="search_glass"
                  onClick={() => {
                    setSearchTerm('')
                    navigate('/models?searchTerm=' + searchTerm)
                  }}
                >
                  <svg
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15C9.21054 15 10.7873 14.4274 12.0491 13.4633L17.2929 18.7071C17.6834 19.0976 18.3166 19.0976 18.7071 18.7071C19.0976 18.3166 19.0976 17.6834 18.7071 17.2929L13.4633 12.0491C14.4274 10.7873 15 9.21054 15 7.5C15 3.35786 11.6421 0 7.5 0ZM2 7.5C2 4.46243 4.46243 2 7.5 2C10.5376 2 13 4.46243 13 7.5C13 10.5376 10.5376 13 7.5 13C4.46243 13 2 10.5376 2 7.5Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  {/* <CIcon icon={loading ? cilCircle : cilSearch} /> */}
                </div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    setSearchTerm('')
                    navigate('/models?searchTerm=' + searchTerm)
                  }}
                >
                  <input
                    type="text"
                    value={searchTerm}
                    id="query_search"
                    onChange={(e) => {
                      setSearchTerm(e.target.value)
                    }}
                    className={styler(s, 'search-result')}
                    name="query"
                    autoComplete="off"
                    placeholder={t('searchplaceholder')}
                  />
                </form>
                <div
                  className={styler(s, 'typeahead')}
                  id="typeahead"
                  style={{ display: 'none' }}
                ></div>
              </div>
            </div>
            <div className="position-relative">
              <CIcon
                icon={cilEnvelopeClosed}
                size="xxl"
                className="position-relative"
                onClick={() => navigate('tickets')}
              />
              {messageCount > 0 ? (
                <CBadge color="danger" position="top-end">
                  {messageCount}
                </CBadge>
              ) : null}
            </div>
            <div
              className={styler(s, 'd-flex')}
              id="avatar-frame"
              onClick={() => {
                setUserDrowpdown((v) => !v)
              }}
            >
              <div className={styler(s, 'sky-avatar-frame')}>
                <img
                  width="40"
                  height="40"
                  src={
                    user.avatar
                      ? env(REACT_APP_SERVER_API) + 'upload/avatars/' + user.avatar
                      : blankAvatar
                  }
                  className={styler(s, 'avatar empty_avatar round-avatar')}
                />
              </div>
              <div className={styler(s, 'd-flex align-self-center')}>
                <div className={styler(s, 'avatar-name')}>
                  <div className={styler(s, 'name')}>
                    {user?.username}
                    <svg
                      className={styler(s, 'menu-arrow')}
                      width="9"
                      height="5"
                      viewBox="0 0 9 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.146447 0.146447C0.341709 -0.0488155 0.658291 -0.0488155 0.853553 0.146447L4.5 3.79289L8.14645 0.146447C8.34171 -0.0488155 8.65829 -0.0488155 8.85355 0.146447C9.04882 0.341709 9.04882 0.658291 8.85355 0.853553L4.85355 4.85355C4.65829 5.04882 4.34171 5.04882 4.14645 4.85355L0.146447 0.853553C-0.0488155 0.658291 -0.0488155 0.341709 0.146447 0.146447Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                  <div
                    className={styler(s, 'ang-avatar-dropdown-block')}
                    style={{ display: userDrowpdown ? 'flex' : 'none' }}
                  >
                    <div className={styler(s, 'ang-avatar-dropdown')}>
                      <div className={styler(s, 'dropdown-name')}>
                        <span>{user?.username}</span>
                        <span></span>
                      </div>
                      <div className={styler(s, 'dropdown-mail')}>{user?.email}</div>
                      <div className={styler(s, 'dropdown-link')}>
                        <a href="#/profile">
                          {t('profile')}
                          <i className={styler(s, 'icofont')}></i>
                        </a>
                      </div>
                      <div className={styler(s, 'dropdown-link')}>
                        <a href="#/subscriptions">
                          {t('subscriptions')}
                          <i className={styler(s, 'icofont')}></i>
                        </a>
                      </div>
                      <div className={styler(s, 'dropdown-link')}>
                        <a href="#/bills">
                          {t('bills')}
                          <i className={styler(s, 'icofont')}></i>
                        </a>
                      </div>
                      <div className={styler(s, 'dropdown-link')}>
                        <a href="#/tickets">
                          {t('tickets')}
                          <i className={styler(s, 'icofont')}></i>
                        </a>
                      </div>
                      {/* <div className={styler(s,'dropdown-link')}>
                        <a href="#/downloads">{t('downloads')}<i className={styler(s,'icofont')}></i></a>
                      </div> */}
                      <div className={styler(s, 'dropdown-link')}>
                        <a
                          href="#"
                          onClick={() => {
                            logout()
                            setTimeout(() => {
                              window.location.reload()
                            }, 200)
                          }}
                        >
                          {t('logout')}
                          {/* <CIcon icon={cilAccountLogout} size='lg' /> */}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </CContainer>
      <div className={styler(s, 'notification')}>
        {notifications.map((i) => {
          return (
            <div key={i.id} style={{ background: i.bg_color, borderColor: i.bg_color }}>
              <CAlert
                className="container"
                dismissible
                onClose={() => {
                  localStorage.setItem('notification_id', i.id)
                }}
                style={{
                  background: i.bg_color,
                  borderColor: i.bg_color,
                  color: i.text_color,
                  padding: '5px',
                  margin: '0 auto',
                  textAlign: 'center',
                }}
                key={i.id}
              >
                <strong>{i.title}</strong>
                <br />
                <span dangerouslySetInnerHTML={{ __html: i.description }} />
              </CAlert>
            </div>
          )
        })}
      </div>
      {/* </div> */}
    </div>
  )
}

export default Header
