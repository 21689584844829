const { default: i18n } = require('./i18n')
const humanizeDuration = require('humanize-duration')

const getHeaders = (extraHeaders = {}) => ({
  Authorization: 'Bearer ' + localStorage.getItem('access_token'),
  Language: i18n.resolvedLanguage,
  ...extraHeaders,
})

const getLanguageHeader = () => ({ Language: i18n.resolvedLanguage })

function styler(s, classes) {
  return classes
    .split(' ')
    .map((i) => (i in s ? s[i] : i))
    .join(' ')
}
function intersect(array1, array2, comparator) {
  let result = array1.filter((item1) => array2.some((item2) => comparator(item1, item2)))
  return result
}

function strongPassowrd(password) {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&*])[A-Za-z\d@#$%^&*]{8,}$/

  if (regex.test(password)) {
    console.log('Password is strong.')
    return true
  } else {
    console.log(
      'Password is weak. It must contain at least 8 characters, including an uppercase letter, a lowercase letter, a number, and a special character.',
    )
    return false
  }
}

const formatDate = (dateString, pattern = 'Y-m-d H:i') => {
  try {
    let d = new Date(dateString)

    return `${d.getFullYear}`
  } catch (error) {
    return dateString
  }
}

const humanizeTime = (dateString) => {
  try {
    let n = new Date()
    let d = new Date(dateString)

    return humanizeDuration(d - n, { largest: 1 }) + ' ago'
  } catch (error) {
    console.error(error)
    return dateString
  }
}

const logout = () => {
  localStorage.removeItem('access_token')
  localStorage.removeItem('refresh_token')
}
module.exports = {
  getHeaders,
  styler,
  getLanguageHeader,
  logout,
  intersect,
  strongPassowrd,
  formatDate,
  humanizeTime,
}
